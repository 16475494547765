import {
  AUTH_PROVIDER,
  CHAIN,
  getUser,
  init,
  openWallet,
  sendToken,
  sendTransaction,
  signIn,
  signInWithProvider,
  signMessage,
  signOut,
  signTransaction,
  User,
  WALLET_TABS,
} from '@ramper/near'
import { BN } from 'bn.js'
import { transactions } from 'near-api-js'
import { useState } from 'react'
import { AUTH_HOST_ENV } from './utils/internal'
;(window as any).RAMPER_ENV = process.env.REACT_APP_RAMPER_ENV || 'local'

// const ref = ''.concat(...(process.env.REACT_APP_VERCEL_GIT_COMMIT_REF || '').replace('/', '-').split('_'))
const AUTH_HOST = AUTH_HOST_ENV[process.env.REACT_APP_RAMPER_ENV! as 'local' | 'preview' | 'staging' | 'prod' | 'dev']

function App() {
  const [user, setUser] = useState<User | null>(getUser())
  const [lastResult, setLastResult] = useState<string>('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [transactionResult, setTransactionResult] = useState<any>(null)

  const handleInit = async () => {
    const instance = await init({
      appName: 'NEAR Test App',
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
      walletProviders: ['my_near_wallet'],
      network: 'testnet',
      theme: 'dark',
      walletTabs: [WALLET_TABS.NFT, WALLET_TABS.COIN],
    })
    console.log('initialized', instance.isLoaded)
    setIsLoaded(instance.isLoaded)
  }

  const handleSignIn = async () => {
    const signInResult = await signIn()
    setUser(signInResult.user ?? null)
    setIsSignedIn(!!signInResult.user)
  }

  const handleInitAndSignIn = async () => {
    const instance = await init({
      appName: 'NEAR Test App',
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
      walletProviders: ['my_near_wallet'],
      network: 'testnet',
      theme: 'dark',
    })

    const signInResult = await signIn()
    setUser(signInResult.user ?? null)

    console.log('initialized', instance.isLoaded)
    setIsLoaded(instance.isLoaded)
  }

  const handleTestSignIn = () => {
    window.open(AUTH_HOST + `/signin/test?chainName=${CHAIN.NEAR}`)
  }

  const handleSendToken = async () => {
    try {
      const result = await sendToken({
        to: '421ccd52db3465153de4591177396dc72e401b0ab1687b8c175491be32fbd148',
        value: '0.01',
        network: 'testnet',
      })
      console.log('sendToekn result: ', result)
      setTransactionResult(result)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSendTransaction = async () => {
    try {
      // // paras sample
      // const result = await sendTransaction({
      //   transactionActions: [
      //     {
      //       receiverId: "x.paras.near",
      //       actions: [
      //         transactions.functionCall('nft_approve', {
      //           token_id: "458431:1",
      //           account_id: "marketplace.paras.near",
      //           msg: {price: "5000000000000000000000000",market_type:"sale",nft_token_id:"near"}
      //         }, new BN("150000000000000"), new BN("800000000000000000000"))
      //       ]
      //     },
      //   ],
      // })
      const result = await sendTransaction({
        transactionActions: [
          {
            receiverId: '421ccd52db3465153de4591177396dc72e401b0ab1687b8c175491be32fbd148',
            actions: [transactions.transfer(new BN(10000000))],
          },
          {
            receiverId: '4ed73379d682c700cdd4233db48ad1da491a55178d7b2fc7521c229d52c5f66c',
            actions: [transactions.transfer(new BN(10000000))],
          },
          {
            receiverId: '1b0d82412d782f626873edfb20a51781ff372cf3b59c3608ce53f573ca2e4a3c',
            actions: [transactions.transfer(new BN(10000000))],
          },
        ],
      })
      console.log('sendTransaction result: ', result)
      setTransactionResult(result)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSignMessage = async () => {
    const message = [12, 13, 14, 15]
    const messageBuffer = new Uint8Array(message)

    try {
      const result = await signMessage({
        message: messageBuffer,
        network: 'testnet',
      })

      console.log('signMessage result: ', result)
      setTransactionResult(result)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSignTransaction = async () => {
    try {
      const result = await signTransaction({
        transaction: {
          receiverId: '421ccd52db3465153de4591177396dc72e401b0ab1687b8c175491be32fbd148',
          actions: [transactions.transfer(new BN(10000000))],
        },
      })
      console.log('sendTransaction result: ', result)
      setTransactionResult(result)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSignOut = () => {
    signOut()
    setUser(null)
  }

  const handleOpenWalletView = () => {
    openWallet()
  }

  const directSignIn = async (provider: string) => {
    setLastResult(
      JSON.stringify(
        await signInWithProvider({
          provider,
        }),
      ),
    )
  }

  return (
    <div
      style={{
        padding: '20px 16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p>Ramper Near Example</p>
        <button onClick={handleInit}>init</button>
        <p>Is Ramper Bundle loaded? {isLoaded ? 'YES' : 'NO'}</p>

        <h3>Direct Sign In</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.GOOGLE)
                  }}
                >
                  Google
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.FACEBOOK)
                  }}
                >
                  Facebook
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.TWITTER)
                  }}
                >
                  Twitter
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.APPLE)
                  }}
                >
                  Apple
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.EMAIL)
                  }}
                >
                  Email
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p>Sign In Result: {lastResult}</p>

        <button onClick={handleSignIn}>Sign in</button>
        <br />
        <button onClick={handleInitAndSignIn}>Init and sign in for testing</button>
        <br />
        <button onClick={handleTestSignIn}>Sign in with test account</button>
        <br />
        <button onClick={handleSignOut}>Sign out</button>
        <p>Signed in? {isSignedIn && user ? 'YES, ' + user.UID : 'NO'}</p>
        <button onClick={handleSendToken}>Test sendToken</button>
        <br />
        <button onClick={handleSendTransaction}>Test sendTransaction</button>
        <br />
        <button onClick={handleSignMessage}>Test signMessage</button>
        <br />
        <button onClick={handleSignTransaction}>Test signTransaction</button>
        <p>TransactionResult: {JSON.stringify(transactionResult)}</p>
        <button onClick={handleOpenWalletView}>Open WalletView</button>
        <br />
      </div>
    </div>
  )
}

export default App
